import { render, staticRenderFns } from "./MessageCost.vue?vue&type=template&id=eb9a3424&scoped=true"
import script from "./MessageCost.vue?vue&type=script&lang=js"
export * from "./MessageCost.vue?vue&type=script&lang=js"
import style0 from "./MessageCost.vue?vue&type=style&index=0&id=eb9a3424&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb9a3424",
  null
  
)

export default component.exports